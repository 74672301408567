import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';
import Slider from "react-slick";
import { Link, useParams } from 'react-router-dom';

import { mission, like } from '@actions/mission';
import { setIsLoading } from '@actions/common';
import { createPlayMission } from '@actions/track';
import StampDetail from './Components/DetailItem/StampDetail';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import config from '@config';
import ShareLink from 'react-facebook-share-link'
import * as helper from '@/helper'

const MissionDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [missionData, setMissionData] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);

  const settings2 = {
    autoplay:true,
    arrows:false,
    // centerMode:'true',
    slidesToShow: 3,
    slidesToScroll: 3
  };
  const [isMissionShare, setIsMissionShare] = useState(false);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(mission(params.mission_id))
      .then((res) => {
        setMissionData(res);

        const additional_images = Array.from({length: 10}, (v, i) => {
          return res[`additional_image${i+1}`]
        })

        setAdditionalImages(additional_images.filter(item => item !== null && item !== undefined))

        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setIsLoading(false));
      })

  }, []);

  useEffect(() => {
    //카카오톡 sdk추가
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [])

  const onPlayMission = () => {
    dispatch(setIsLoading(true));
    dispatch(createPlayMission(params.mission_id))
    .then((res) => {
      dispatch(setIsLoading(false));
      history.navigate(`/missions/${params.mission_id}/${missionData.type}/play/${res.id}`, {replace: true})
    })
    .catch(message => {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      dispatch(setIsLoading(false));
      history.navigate('/', {replace: true})
    });
  }

  const onShareToKakaoTalk = () => {
    if(window.Kakao) {  
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(config.kakaoAppKey);
      }

      kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: missionData.title,
          description: helper.extractContent(missionData.description),
          imageUrl: `${config.apiServerHost}/missions/attachment/${missionData?.intro_image?.server_filename}`,
          link: {
            mobileWebUrl: `${config.baseUrl}/missions/${params.mission_id}/view`,
            webUrl: `${config.baseUrl}/missions/${params.mission_id}/view`,
          },
        },
        buttons: [
          {
            title: '미션 투어 하러가기',
            link: {
              mobileWebUrl: `${config.baseUrl}/missions/${params.mission_id}/view`,
              webUrl: `${config.baseUrl}/missions/${params.mission_id}/view`,
            },
          },
        ],
      })

      setIsMissionShare(false);
    }
  }

  const onLike = () => {
    dispatch(like(missionData._id))
    .then(res => {
      setMissionData({...missionData, is_liked: res.result})
    })
  }

  const onCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
    toast.success('링크가 복사되었습니다.');
    setIsMissionShare(false);
  }

  return (
    <>
      {missionData && <div className="container">
        <div className="container_inner">
          <p className="detail_cate">
            {missionData.type === 'solo' && <><img src={require('@assets/img/icon_h1_green.png')} /><strong>1인 미션 투어</strong></>}
            {missionData.type === 'team' && <><img src={require('@assets/img/icon_h2_blue.png')} /><strong className='colblue'>단체 미션 투어</strong></>}
            {missionData.type === 'stamp' && <><img src={require('@assets/img/icon_h3_red.png')} /><strong className='colred'>스탬프 투어</strong></>}
          </p>
          <h2 className="detail_title">{missionData.title}</h2>
          <dl className="detail_mission_info">
            {missionData.type !== 'team' && <>
            <dt>미션 기간</dt>
            <dd>
              {missionData.is_unlimit_date ? '상시' : <><Moment format='YYYY.MM.DD HH:mm'>{missionData.from_date}</Moment> ~ <Moment format='YYYY.MM.DD HH:mm'>{missionData.to_date}</Moment></>}
            </dd>
            </>}
            {missionData.type === 'team' && <>
            <dt>미션 시작일</dt>
            <dd>
              <Moment format='YYYY.MM.DD HH:mm'>{missionData.from_date}</Moment>
            </dd>
            </>}
            <dt>미션 시간</dt>
            <dd>
              <span>{missionData.limit_time}시간</span>
              <div className="mission_info_buttons">
                <button type="button" className="btn_share mr15" onClick={()=>setIsMissionShare(true)}></button>
                <button type="button" className={`btn_like ${missionData.is_liked ? "btn_liked" : ""}`} onClick={()=>onLike()}></button>
              </div>
            </dd>
          </dl>
          {missionData.type !== 'team' && <div className="detail_result">
            <div className="triple_wrap">
              <div>
                <small>참여자</small>
                <small><strong className="colgreen">{missionData.total_mission_member_count}</strong>명</small>
              </div>
              <div>
                <small>성공</small>
                <small><strong className="colred">{missionData.success_mission_count}</strong>번</small>
              </div>
              <div>
                <small>탈락</small>
                <small><strong className="coldg">{missionData.fail_mission_count}</strong>번</small>
              </div>
            </div>
          </div>}
        </div>
        <span className="divide_thick mb20"></span>
        <div className="container_inner container_fit">
          {additionalImages.length > 0 && <div className="detail_img_slider_wrap">
            <Slider className="detail_img_slider" {...{
                autoplay:true,
                arrows:false,
                dots:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                customPaging: function(i) {
                  return (
                    <span>{i+1 + " / " + additionalImages.length}</span>
                  )
                }
              }}>
              {additionalImages.map(image=><div>
                <img src={`${config.apiServerHost}/missions/attachment/${image?.server_filename}`} alt="여행이미지" onError={e=>e.target.src = 'https://via.placeholder.com/282'}/>
              </div>)}
            </Slider>
          </div>}
          <div className="detail_mission_desc mb30">
            <p className="colblue mb15"><strong><small>미션 설명</small></strong></p>
            <p dangerouslySetInnerHTML={{__html: missionData.description}} />
          </div>
          <div className="detail_rewards_slider_wrap">
            <p className="colblue mb15"><strong><small>경품 안내</small></strong></p>
            <Slider className="detail_rewards_slider" {...settings2}>
              {missionData.giveaways.map(item=><div className="detail_mission_reward" key={item._id}>
                <img src={`${config.apiServerHost}/giveaways/attachment/${item?.image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/282'}/>
                <span>{item.title}</span>
              </div>)}
            </Slider>
            {missionData.giveaway_description && <p dangerouslySetInnerHTML={{__html: missionData.giveaway_description}} />}
          </div>
        </div>
        {/* 스탬프 투어일 경우에만 노출 */}
        {missionData.type === 'stamp' && <StampDetail data={missionData}/>}
        {/* 스탬프 투어일 경우에만 노출 */}
        
        {/* 게임참가중일때 */}
        {missionData.current_mission && missionData.current_mission.status === 'play' && <div className="mission_bottom"><Link to={`/missions/${params.mission_id}/${missionData.current_mission.mission.type}/play/${missionData.current_mission._id}`} className="btn_lg mission_join">참여중</Link></div> } 
        {/* 팀 생성중일때 */}
        {missionData.current_mission && missionData.current_mission.status === 'team-building' && <div className="mission_bottom"><Link to={`/missions/${params.mission_id}/${missionData.current_mission.mission.type}/join`} className="btn_lg mission_join">참여중</Link></div> } 
        {/* 팀 인원 부족 */}
        {missionData.current_mission && missionData.current_mission.status === 'team-building-fail' && <div className="mission_bottom"><button type='button' className="btn_lg bg_gray">{missionData.current_mission.mission.cancel_message || '미션 참가인원 부족으로 취소'}</button></div> } 
        {
          missionData.type !== 'team' 
          && !missionData.current_mission 
          && 
          ((missionData.is_unlimit_date) || (moment().tz("Asia/Seoul").isAfter(missionData.from_date) && moment().tz("Asia/Seoul").isBefore(missionData.to_date)))
           && <div className="mission_bottom"><button type='button' className="btn_lg mission_join" onClick={()=>onPlayMission()}>참여하기</button></div>
        }
        {
          missionData.type === 'team' && moment().tz("Asia/Seoul").isBefore(missionData.from_date) && <div className="mission_bottom"><Link to={`/missions/${params.mission_id}/team/join`} className="btn_lg mission_join">참여하기</Link></div>
        }
      </div>}
      <div id="detail_share" className="modal fade" style={{display: isMissionShare? 'block':'none'}}>
          <div className='modal-backdrop in'></div>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                  <div>
                    <div className="detail_share_header">
                      <strong>공유하기</strong>
                      <button className="btn_modal_close" onClick={()=>setIsMissionShare(false)}><span className="hide">모달 닫기</span></button>
                    </div>
                    <div className="detail_share_links">
                      <a href="#" onClick={()=>onCopyUrl()}>
                      <img src={require('@assets/img/img_copy.png')} alt="링크 복사" />
                      <small>링크 복사</small>
                    </a>
                    <a href="#" onClick={()=>onShareToKakaoTalk()}>
                      <img src={require('@assets/img/img_kakao.png')} alt="카카오톡으로 공유" />
                      <small>카카오톡</small>
                    </a>
                    <ShareLink link={window.location.href}>
                      {link => (
                          <a href={link} target='_blank' onClick={()=>setIsMissionShare(false)}>
                          <img src={require('@assets/img/img_fb.png')} alt="페이스북으로 공유" />
                          <small>페이스북</small>
                        </a>
                      )}
                    </ShareLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default MissionDetail;
